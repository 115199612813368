import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Spring, animated } from "react-spring";
import { BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";

import firstIcon11stIconImg from "../../pages/home/images/Avatar-GuanChun-p-800.jpeg";
import secondIcon12ndIconImg from "../../pages/home/images/Avatar-Clay-p-800.jpeg";
import thirdIcon12ndIconImg from "../../pages/home/images/Avatar-Raymond-p-800.jpeg";
import fourthIcon12ndIconImg from "../../pages/home/images/avatar-harry-p-800.jpeg";
import fifthIcon12ndIconImg from "../../pages/home/images/Avatar-hoyt-p-800.jpeg";
import sixthIcon12ndIconImg from "../../pages/home/images/Avatar-CatchOnLabs-p-800.jpeg";
import seventhIcon12ndIconImg from "../../pages/home/images/Avatar-mod_CandyKen.jpg";
import eighthIcon12ndIconImg from "../../pages/home/images/Avatar-mod_layan.jpg";
import ninthIcon12ndIconImg from "../../pages/home/images/Avatar-mod_Yeekee.jpg";
import tenthIcon12ndIconImg from "../../pages/home/images/Avatar-mod_Henry.jpg";
import eleventhIcon12ndIconImg from "../../pages/home/images/Avatar-mod_KKF.jpg";
import twelvethIcon12ndIconImg from "../../pages/home/images/Avatar-mod_Weilun.jpg";
import threethIcon12ndIconImg from "../../pages/home/images/Avatar-frey.jpg";
import fourteenthIcon12ndIconImg from "../../pages/home/images/avatar-stacy.jpg";
import secondBgImg from "../../pages/home/images/second-bg.png";

export const Team = () => {
  return (
    <section
      id="team"
      data-w-id="e6179acb-c521-dc39-9bbe-87a5a872ebe7"
      class="section team"
    >
      <div class="container team">
        <div class="content-wrapper team">
          <div
            data-w-id="04c83c98-394e-a071-a9cc-d631285236d8"
            class="square-dot about meet-team"
          ></div>
          <div
            data-w-id="1431c1ef-b7d0-3a48-98cf-37c541982cb7"
            class="square-dot _1st-icon"
          ></div>
          <div
            data-w-id="e67b1645-0cd2-3167-3c4a-8d19ff207c59"
            class="square-dot _3rd-icon"
          ></div>
          <div
            data-w-id="3e009af2-ec51-5323-08de-325a88099635"
            class="square-dot _4th-icon"
          ></div>
          <div
            data-w-id="988845e6-3901-bbcd-b904-6bfcaaffefb2"
            class="square-dot _5th-icon"
          ></div>
          <div
            data-w-id="3193c81b-a32a-c1dd-887a-2cc5627e1be4"
            class="square-dot _2nd-icon"
          ></div>
          <div
            data-w-id="3193c81b-a32a-c1dd-887a-2cc5627e1be4"
            class="square-dot _6th-icon"
          ></div>
          <div
            data-w-id="3193c81b-a32a-c1dd-887a-2cc5627e1be4"
            class="square-dot _7th-icon"
          ></div>
          <div
            data-w-id="3193c81b-a32a-c1dd-887a-2cc5627e1be4"
            class="square-dot _8th-icon"
          ></div>
          <div
            data-w-id="3193c81b-a32a-c1dd-887a-2cc5627e1be4"
            class="square-dot _9th-icon"
          ></div>
          <div
            data-w-id="3193c81b-a32a-c1dd-887a-2cc5627e1be4"
            class="square-dot _10th-icon"
          ></div>
          <div
            data-w-id="3193c81b-a32a-c1dd-887a-2cc5627e1be4"
            class="square-dot _11th-icon"
          ></div>
          <div
            data-w-id="3193c81b-a32a-c1dd-887a-2cc5627e1be4"
            class="square-dot _12th-icon"
          ></div>
          <div
            data-w-id="2424c2c6-1344-b2a5-e3f6-089b4986899b"
            class="content-left full"
          >
            <div>Boxcatplanet</div>
            <h2 class="content-title parts small">about the team</h2>
          </div>
          <VisibilitySensor partialVisibility offset={{ bottom: 70 }}>
            {({ isVisible }) => (
              <Spring
                delay={150}
                from={{ transform: "translateY(50px)" }}
                to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
              >
                {(styles) => {
                  return (
                    <animated.div
                      data-w-id="2424c2c6-1344-b2a5-e3f6-089b498689a6"
                      class="content-right full"
                      style={styles}
                    >
                      <div class="horz-wrap">
                        <img
                          src={firstIcon11stIconImg}
                          loading="lazy"
                          alt="Boxcatplanet team member"
                          class="team-img"
                        />
                        <h2 class="content-title team-member">
                          Guan
                          <br />
                          Chun
                        </h2>
                      </div>
                      <div class="separator mob"></div>
                      <p class="content-sub team">
                        Founder and Artist. <br />
                        Visual artist and illustrator.
                      </p>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/GuanChunArt"
                        class="member-twitter"
                      >
                        <BsTwitter size="2em" loading="lazy" alt="" />
                      </a>
                    </animated.div>
                  );
                }}
              </Spring>
            )}
          </VisibilitySensor>
          <VisibilitySensor partialVisibility offset={{ bottom: 70 }}>
            {({ isVisible }) => (
              <Spring
                delay={150}
                from={{ transform: "translateY(50px)" }}
                to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
              >
                {(styles) => {
                  return (
                    <animated.div
                      data-w-id="2140160e-19ef-74f5-8806-694bfc8254c6"
                      class="content-right full"
                      style={styles}
                    >
                      <div class="horz-wrap">
                        <img
                          src={secondIcon12ndIconImg}
                          loading="lazy"
                          alt="Boxcatplanet team member"
                          class="team-img"
                        />
                        <h2 class="content-title team-member">
                          Clay
                          <br />
                          Wang
                        </h2>
                      </div>
                      <div class="separator mob"></div>
                      <p class="content-sub team">
                        Founder and product manager. Producer and artist agent.
                        <br />
                        Over 15 years experience in the entertainment industry.
                      </p>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/Clay_boxcat"
                        class="member-twitter"
                      >
                        <BsTwitter size="2em" loading="lazy" alt="" />
                      </a>
                    </animated.div>
                  );
                }}
              </Spring>
            )}
          </VisibilitySensor>
          <VisibilitySensor partialVisibility offset={{ bottom: 70 }}>
            {({ isVisible }) => (
              <Spring
                delay={150}
                from={{ transform: "translateY(50px)" }}
                to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
              >
                {(styles) => {
                  return (
                    <animated.div
                      data-w-id="7a1d7686-435f-a9c7-2d89-ba305c6f2a30"
                      class="content-right full"
                      style={styles}
                    >
                      <div class="horz-wrap">
                        <img
                          src={thirdIcon12ndIconImg}
                          loading="lazy"
                          alt="Boxcatplanet team member"
                          class="team-img"
                        />
                        <h2 class="content-title team-member">
                          Raymond
                          <br />
                          Lu
                        </h2>
                      </div>
                      <div class="separator mob"></div>
                      <p class="content-sub team">
                        IP Professional, Creative &amp; Events Marketing Expert
                        <br />
                        Over 12 years experience with The Walt Disney Company.
                      </p>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/Raymondlu945"
                        class="member-twitter"
                      >
                        <BsTwitter size="2em" loading="lazy" alt="" />
                      </a>
                    </animated.div>
                  );
                }}
              </Spring>
            )}
          </VisibilitySensor>
          <VisibilitySensor partialVisibility offset={{ bottom: 70 }}>
            {({ isVisible }) => (
              <Spring
                delay={150}
                from={{ transform: "translateY(50px)" }}
                to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
              >
                {(styles) => {
                  return (
                    <animated.div
                      data-w-id="4bbb5cc6-09de-44e7-7461-2c36113b824d"
                      class="content-right full"
                      style={styles}
                    >
                      <div class="horz-wrap">
                        <img
                          src={fourthIcon12ndIconImg}
                          loading="lazy"
                          alt="Boxcatplanet team member"
                          class="team-img"
                        />
                        <h2 class="content-title team-member">
                          Harry
                          <br />
                          Zhang
                        </h2>
                      </div>
                      <div class="separator mob"></div>
                      <p class="content-sub team">
                        Visual coordinator. <br />
                        AIPP Accredited professional photographer.
                      </p>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/huntlyharry"
                        class="member-twitter"
                      >
                        <AiFillInstagram size="2em" loading="lazy" alt="" />
                      </a>
                    </animated.div>
                  );
                }}
              </Spring>
            )}
          </VisibilitySensor>
          <VisibilitySensor partialVisibility offset={{ bottom: 70 }}>
            {({ isVisible }) => (
              <Spring
                delay={150}
                from={{ transform: "translateY(50px)" }}
                to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
              >
                {(styles) => {
                  return (
                    <animated.div
                      data-w-id="04c83c98-394e-a071-a9cc-d631285236cf"
                      class="content-right full"
                      style={styles}
                    >
                      <div class="horz-wrap">
                        <img
                          src={fifthIcon12ndIconImg}
                          loading="lazy"
                          alt="Boxcatplanet team member"
                          class="team-img"
                        />
                        <h2 class="content-title team-member">
                          Hoyt
                          <br />
                          Ho
                        </h2>
                      </div>
                      <div class="separator mob"></div>
                      <p class="content-sub team">
                        Visual Director.
                        <br />
                        Visual and graphic designer. Over 7 years experience
                        with Xiaomi and IQIYI.
                      </p>
                    </animated.div>
                  );
                }}
              </Spring>
            )}
          </VisibilitySensor>
          <VisibilitySensor partialVisibility offset={{ bottom: 70 }}>
            {({ isVisible }) => (
              <Spring
                delay={150}
                from={{ transform: "translateY(50px)" }}
                to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
              >
                {(styles) => {
                  return (
                    <animated.div
                      data-w-id="2424c2c6-1344-b2a5-e3f6-089b498689a6"
                      class="content-right full"
                      style={styles}
                    >
                      <div class="horz-wrap">
                        <img
                          src={sixthIcon12ndIconImg}
                          loading="lazy"
                          alt="Boxcatplanet team member"
                          class="team-img"
                        />
                        <h2 class="content-title team-member">
                          NFT Black <br />
                          magic
                        </h2>
                      </div>
                      <div class="separator mob"></div>
                      <p class="content-sub team">
                        WEB3 DEV in Silicon Valley.
                        <br />
                        Over 5 years industry experience of software
                        development.
                      </p>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/MrsZaaa"
                        class="member-twitter"
                      >
                        <BsTwitter size="2em" loading="lazy" alt="" />
                      </a>
                    </animated.div>
                  );
                }}
              </Spring>
            )}
          </VisibilitySensor>
          <VisibilitySensor partialVisibility offset={{ bottom: 70 }}>
            {({ isVisible }) => (
              <Spring
                delay={150}
                from={{ transform: "translateY(50px)" }}
                to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
              >
                {(styles) => {
                  return (
                    <animated.div
                      data-w-id="4bbb5cc6-09de-44e7-7461-2c36113b824d"
                      class="content-right thirdWrapper"
                      style={styles}
                    >
                      <div class="third">
                        <div class="horz-wrap format">
                          <img
                            src={seventhIcon12ndIconImg}
                            loading="lazy"
                            alt="Boxcatplanet team member"
                            class="team-img mod"
                          />
                          <h2 class="content-title team-member">
                            Candy
                            <br />
                            Ken
                          </h2>
                        </div>
                        <div class="separator mob first"></div>
                        <p class="content-sub team">
                          Community
                          <br />
                          Manager
                        </p>
                      </div>
                      <div class="third">
                        <div class="horz-wrap">
                          <img
                            src={threethIcon12ndIconImg}
                            loading="lazy"
                            alt="Boxcatplanet team member"
                            class="team-img mod"
                          />
                          <h2 class="content-title team-member">FREY</h2>
                        </div>
                        <div class="separator mob"></div>
                        <p class="content-sub team">BD</p>
                      </div>
                      <div class="third">
                        <div class="horz-wrap format">
                          <img
                            src={fourteenthIcon12ndIconImg}
                            loading="lazy"
                            alt="Boxcatplanet team member"
                            class="team-img mod"
                          />
                          <h2 class="content-title team-member">
                            STACY
                          </h2>
                        </div>
                        <div class="separator mob first"></div>
                        <p class="content-sub team">
                          Collab<br />Manager
                        </p>
                      </div>
                    </animated.div>
                  );
                }}
              </Spring>
            )}
          </VisibilitySensor>
          <VisibilitySensor partialVisibility offset={{ bottom: 70 }}>
            {({ isVisible }) => (
              <Spring
                delay={150}
                from={{ transform: "translateY(50px)" }}
                to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
              >
                {(styles) => {
                  return (
                    <animated.div
                      data-w-id="4bbb5cc6-09de-44e7-7461-2c36113b824d"
                      class="content-right thirdWrapper"
                      style={styles}
                    >
                      <div class="third">
                        <div class="horz-wrap">
                          <img
                            src={eighthIcon12ndIconImg}
                            loading="lazy"
                            alt="Boxcatplanet team member"
                            class="team-img mod"
                          />
                          <h2 class="content-title team-member">Layan</h2>
                        </div>
                        <div class="separator mob"></div>
                        <p class="content-sub team">Mod</p>
                      </div>
                      <div class="third">
                        <div class="horz-wrap">
                          <img
                            src={ninthIcon12ndIconImg}
                            loading="lazy"
                            alt="Boxcatplanet team member"
                            class="team-img mod"
                          />
                          <h2 class="content-title team-member">YeeKee</h2>
                        </div>
                        <div class="separator mob"></div>
                        <p class="content-sub team">Mod</p>
                      </div>
                      <div class="third">
                        <div class="horz-wrap">
                          <img
                            src={eleventhIcon12ndIconImg}
                            loading="lazy"
                            alt="Boxcatplanet team member"
                            class="team-img mod"
                          />
                          <h2 class="content-title team-member">kkf</h2>
                        </div>
                        <div class="separator mob"></div>
                        <p class="content-sub team">Mod</p>
                      </div>


                    </animated.div>
                  );
                }}
              </Spring>
            )}
          </VisibilitySensor>
          <VisibilitySensor partialVisibility offset={{ bottom: 70 }}>
            {({ isVisible }) => (
              <Spring
                delay={150}
                from={{ transform: "translateY(50px)" }}
                to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
              >
                {(styles) => {
                  return (
                    <animated.div
                      data-w-id="4bbb5cc6-09de-44e7-7461-2c36113b824d"
                      class="content-right thirdWrapper"
                      style={styles}
                    >
                      <div class="third">
                        <div class="horz-wrap">
                          <img
                            src={tenthIcon12ndIconImg}
                            loading="lazy"
                            alt="Boxcatplanet team member"
                            class="team-img mod"
                          />
                          <h2 class="content-title team-member">
                            HENRY
                            <br />
                            SUN
                          </h2>
                        </div>
                        <div class="separator mob"></div>
                        <p class="content-sub team">Mod</p>
                      </div>
                      <div class="third">
                        <div class="horz-wrap">
                          <img
                            src={twelvethIcon12ndIconImg}
                            loading="lazy"
                            alt="Boxcatplanet team member"
                            class="team-img mod"
                          />
                          <h2 class="content-title team-member">
                            Wei <br />
                            lun
                          </h2>
                        </div>
                        <div class="separator mob"></div>
                        <p class="content-sub team">
                          Trial
                          <br />
                          Mod
                        </p>
                      </div>

                    </animated.div>
                  );
                }}
              </Spring>
            )}
          </VisibilitySensor>
        </div>
        <img
          src={secondBgImg}
          loading="lazy"
          alt="Background layer"
          class="second-bg"
        />
      </div>
    </section>
  );
};
