import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Spring, animated } from "react-spring";

import { FooterImage } from "./background";
import { FooterBanner } from "./banner";



export const Footer = () => {
  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => (
        <Spring
          delay={150}
          from={{ transform: "translateY(100px)" }}
          to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
        >
          {(styles) => {
            return (
              <animated.div style={styles}>
                <div class="footer wf-section">
                  <FooterImage />
                  <FooterBanner />
                </div>
              </animated.div>
            );
          }}
        </Spring>
      )}
    </VisibilitySensor>
  );
};
