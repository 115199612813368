import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Spring, animated } from "react-spring";

import rainbowImg from "../../pages/home/images/图层-12-拷贝-3.png";
import rainbow500Img from "../../pages/home/images/图层-12-拷贝-3-p-500.png";

import "./nft-lp.webflow.css";

export const Roadmap = () => {
  return (
    <div id="roadmap" class="section section__5 wf-section">
      <div class="roadmap_container">
        <VisibilitySensor partialVisibility offset={{ top: 10 }}>
          {({ isVisible }) => (
            <Spring
              delay={150}
              from={{ transform: "translateY(50px)" }}
              to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
            >
              {(styles) => {
                return (
                  <animated.div
                    data-w-id="d49075f8-96f5-6043-0820-69efa533ea68"
                    style={styles}
                    class="roadmap"
                  >
                    <div class="roadmap_head">
                      <div class="heading__block">
                        <h2>ROAD MAP</h2>
                        <div class="squre squre-4"></div>
                      </div>
                      <div class="sm_text sm_text-1">BoxcatPlanet</div>
                    </div>
                    <div class="roadmap__list">
                      <div class="roadmap__item">
                        <div class="squre-__r"></div>
                        <h4 class="roadmap__name">In September 2022</h4>
                        <div class="roadmap__text-wrap">
                          <p class="sm_text">
                            The BoxcatPlanet NFT series will be launched.
                          </p>
                        </div>
                      </div>
                      <div class="roadmap__item">
                        <div class="squre-__r-2"></div>
                        <h4 class="roadmap__name">In September 2022</h4>
                        <div class="roadmap__text-wrap">
                          <p class="sm_text">
                            Launch the Holder points system and Diamond Hand program at the same time.
                          </p>
                        </div>
                      </div>
                      <div class="roadmap__item">
                        <div class="squre-__r-3"></div>
                        <h4 class="roadmap__name">In October 2022</h4>
                        <div class="roadmap__text-wrap">
                          <p class="sm_text">
                            Airdrop NFT to Diamond Hand members who meet the appropriate requirements
                            [This NFT can be exchanged for an Guan Chun autograph limited edition crystal ball
                            (market value 499RMB) by burning the NFT, and can also be traded on the secondary market].
                          </p>
                        </div>
                      </div>
                      <div class="roadmap__item">
                        <div class="squre-__r-4"></div>
                        <h4 class="roadmap__name">In November 2022</h4>
                        <div class="roadmap__text-wrap">
                          <p class="sm_text">
                            Release the joint clothing collection and pet supplies collection,
                            Holder can choose to redeem points for hoodies or pet supplies according to their needs.
                          </p>
                        </div>
                      </div>
                      <div class="roadmap__item roadmap__item-last">
                        <div class="squre-__r-5"></div>
                        <h4 class="roadmap__name">In 2023 Q1</h4>
                        <div class="roadmap__text-wrap">
                          <p class="sm_text">
                            Artist Guan Chun will hold a BoxcatPlanet-themed offline exhibition,
                            the BoxcatPlanet NFT will also be exhibited alongside Guan Chun's shelf works.
                            Proceeds from the sale of BoxcatPlanet's Web2 shelves during the exhibition will be deposited to the Community Fund to support community building.
                          </p>
                        </div>
                        <div class="dotted__block">
                          <p class="sm_text">
                            With development of the program &amp; community of
                            BoxcatPlanet, the Roadmap will be keeping updated to
                            deliver great contents for the lovers of
                            BoxcatPlanet.
                          </p>
                        </div>
                      </div>
                    </div>
                  </animated.div>
                );
              }}
            </Spring>
          )}
        </VisibilitySensor>
      </div>
      <img
        src={rainbowImg}
        loading={"lazy"}
        sizes={"(max-width: 767px) 100vw, (max-width: 991px) 280px, 24vw"}
        srcSet={`${rainbow500Img} 500w, ${rainbowImg} 654w`}
        alt=""
        class="image"
      />
    </div>
  );
};
