import React from "react";

import "./mintPage.css";

import { formatWalletAddress } from "../../../utils/util";

export const NfcPage = ({ data }) => {
  return (
    <div class="section-mint-nfc wf-section">
      {data.state.address && (
        <span class="w-address-connected">
          Connected: {formatWalletAddress(data.state.address)}
        </span>
      )}
      <div class="mint-div-nfc">
        <button class="w-button" connect-button="0">
          Connect wallet
        </button>
        <button
          class="w-button"
          mint-button="boxcat"
          contractaddress="0x56472574f88cc982077a58702ed0a13f63e05c2f"
        >
          Mint
        </button>
      </div>
    </div>
  );
};
