import { whitelistAddresses } from "./configuration";
import { ALLOWLIST_LINK } from "./const";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import * as _ from "lodash";
import * as Web3 from 'web3';

export const formatWalletAddress = (address) => {
  return address && `${address.substring(0, 4)}...${address.substring(40)}`;
};

export const formatTxn = (txn) => {
  return txn && `${txn.substring(0, 4)}...${txn.substring(62)}`;
};

export const transactionHostURL = (chainId) => {
  let host = "https://etherscan.io/tx";
  switch (chainId) {
    case 4:
      host = "https://rinkeby.etherscan.io/tx";
      break;
    case 5:
      host = "https://goerli.etherscan.io/tx";
      break;
    default:
      break;
  }
  return host;
};

export const getProof = async (data) => {
  const leafNodes = whitelistAddresses.map((address) => keccak256(address));
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

  const leaf = keccak256(data.state.address);
  const proof = merkleTree.getHexProof(leaf);
  console.log("PROOF", proof);
  return proof;
};


export const getAllowlist = async (data) => {
  return new Promise(async (resolve, reject) => {
    if (!data.state.web3) {
      data.setState({
        checkingAllowlistStatus: 'failed',
      });
      reject(new Error("Error: Please connect correct wallet."));
    } else {
      const address = data.state.address.toLowerCase();
      if (address) {
        return fetch(`${ALLOWLIST_LINK}${address}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then(async (response) => {
            // console.log(response);
            const address = _.get(response, ["Item", "address"]);
            const signature = _.get(response, ["Item", "signature"]);
            const tier = _.get(response, ["Item", "tier"]);
            const price = _.get(response, ["Item", "price"]);
            if (address && signature && tier && price) {
              data.setState({
                signature,
                tier,
                price,
              })
              // console.log('signature', signature, tier, price);
              resolve([signature, tier, price]);
            }
            else {
              resolve(["", 0, ""]);
            }
          })
          .catch((err) => {
            resolve(["", 0, ""]);
          });
      }
    }
  })
}

export const convertWeiToETH = (wei) => {
  return Web3.utils.fromWei(wei, 'ether')
}