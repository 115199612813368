import React from "react";

import { BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import logoImg from "../../pages/home/images/logo.png";
import alphaImg from "../../pages/home/images/nftaerfa.jpg";
import chinlinkImg from "../../pages/home/images/Chainlink Logo Black.png";
import soapImg from "../../pages/home/images/soapstudio.jpg";
import logo500Img from "../../pages/home/images/logo-p-500.png";
import openseaImg from "../../pages/home/images/opensea.png";
import { OPENSEA_URL } from "../../utils/configuration";

export const FooterBanner = () => {
  return (
    <div class="container">
      <div class="footer__wrap">
        <div class="footer__l">
          <a href="/" class="footer__logo w-inline-block">
            <img
              src={logoImg}
              loading="lazy"
              sizes="(max-width: 479px) 51vw, (max-width: 767px) 155.828125px, (max-width: 991px) 108.2890625px, 11vw"
              srcSet={`${logo500Img} 500w, ${logoImg} 561w`}
              alt=""
              class="logo"
            />
          </a>
          <div class="footer__center">
            <p class="footer__text">© 2022 BOXCATPLANET ALL RIGHTS RESERVED.</p>
            <a
              href="https://www.termsfeed.com/live/f63438a9-a67d-4991-a289-3de5aee4a303"
              class="footer__text"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
        <div class="footer__c">
          <a >PARTNERSHIP</a>
          <img
            src={alphaImg}
            loading="lazy"
            alt=""
            class="logo1"
          />
          <img
            src={chinlinkImg}
            loading="lazy"
            alt=""
            class="logo1"
          />
          <img
            src={soapImg}
            loading="lazy"
            alt=""
            class="logo1"
          />
        </div>
        <div class="footer_r">
          <p class="footer__text" id={"jointhecommunity"}>
            JOIN THE COMMUNITY
          </p>
          <div class="footer_socials">
            <a
              href={OPENSEA_URL}
              target="_blank"
              rel="noreferrer"
              class="w-inline-block"
            >
              <img
                src={openseaImg}
                style={{ width: "20px" }}
                loading="lazy"
                alt="Opeansea"
              />
            </a>
            <a
              href="https://t.co/vLz3ZHfBDv"
              target="_blank"
              class="w-inline-block"
              rel="noreferrer"
            >
              <FaDiscord size={20} loading="lazy" alt="" />
            </a>
            <a
              href="https://twitter.com/boxcatplanet"
              target="_blank"
              class="w-inline-block"
              rel="noreferrer"
            >
              <BsTwitter size={20} loading="lazy" alt="" />
            </a>
            <a
              href="https://www.instagram.com/boxcatplanet"
              target="_blank"
              class="w-inline-block"
              rel="noreferrer"
            >
              <AiFillInstagram size={20} loading="lazy" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
