import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Spring, animated } from "react-spring";
import kvImg from "../../pages/home/images/HOME KV 3.gif";
import videos from "../../pages/home/videos/HOMEKVnew-transcode.mp4";


export const Banner = () => {
  return (
    <div class="section hero">
      <div class="container hero">
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              delay={150}
              from={{ transform: "translateY(100px)" }}
              to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
            >
              {(styles) => {
                return (
                  <animated.div class="content-wrapper" style={styles}>
                    <div class="container">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<video autoplay loop muted playsinline width="100%"
                            height="100%">
                            <source src=${videos} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>`,
                        }}
                      />
                    </div>
                    {/* <img
                      src={kvImg}
                      loading="lazy"
                      sizes="(max-width: 479px) 90vw, (max-width: 767px) 79vw, (max-width: 991px) 82vw, 66vw"
                      alt="Hero Image"
                    /> */}
                  </animated.div>
                );
              }}
            </Spring>
          )}
        </VisibilitySensor>
      </div>
    </div>
  );
};
