import React from "react";

import "./mintPage.css";

import { CheckAllowlistStatus } from "./checkAllowlistStatus";
import { AboutToMint } from "./aboutToMint";
import { AbleToMint } from "./ableToMint";
import { UnableToMint } from "./unableToMint";
import { DefaultMint } from "./defaultMint";
import soldout from "../../mint/images/sold out X2.gif";
import { OPENSEA_URL } from "../../../utils/configuration";

import { formatWalletAddress } from "../../../utils/util";

import mintRightImage from "../../mint/images/frame-right-p-500.png";
import mintLeftImage from "../../mint/images/frame-left.png";

export const MintPage = ({ data }) => {
  return (
    <div class="section-mint wf-section">
      {data.state.address &&
        <span class="w-address-connected">
          Connected: {formatWalletAddress(data.state.address)}
        </span>
      }
      <div class="mint-div">
        <img
          src={soldout}
          loading="lazy"
          alt=""
          className="soldout"
        />
        <a
          href={OPENSEA_URL}
          target="_blank"
          rel="noreferrer"
          class="allowlistbtn allowlistbtnsize"
        >VIEW ON OPENSEA</a>
        {/* <div class="mint-sub-div">
          {data.state.connected ? (
            data.state.mintStatus === 0 || data.state.mintStatus === 1 ? (
              <CheckAllowlistStatus data={data} />
            ) : (
              <AbleToMint data={data} />
            )
          ) : (
            <DefaultMint data={data} />
          )}
        </div> */}
      </div>
      <img
        src={mintRightImage}
        loading="lazy"
        sizes="100vw"
        alt=""
        class="mint-right"
      />
      <img src={mintLeftImage} loading="lazy" alt="" class="mint-left" />
    </div>
  );
};
