import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Spring, animated } from "react-spring";

import catImg from "../../pages/home/images/base_resize.gif";

export const About = () => {
  return (
    <section
      id="about"
      data-w-id="8f2077aa-5eaf-07fe-0ce6-dafe84b0dfd1"
      class="section about"
    >
      <div class="container about">
        <VisibilitySensor partialVisibility offset={{ bottom: 80 }}>
          {({ isVisible }) => (
            <Spring
              delay={150}
              from={{ transform: "translateY(100px)" }}
              to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
            >
              {(styles) => {
                return (
                  <animated.div class="content-wrapper parts" style={styles}>
                    <div
                      data-w-id="8e8fc568-15a0-3149-49b4-f34a4edfd626"
                      class="square-dot about"
                    ></div>

                    <div
                      data-w-id="68d59a6d-47f4-17bc-9bb5-1e033de6b7a5"
                      class="content-left white"
                    >
                      <img
                        src={catImg}
                        loading="lazy"
                        alt="About Boxcat"
                        class="parts-img"
                      />
                      <div
                        data-w-id="48b1ac2c-31a2-25ea-8977-71a8edffcf4d"
                        class="abs-bar about"
                      >
                        <div class="content-small parts">
                          HOW ARE THINGS GOING
                          <br />
                          on boxcat planet?
                        </div>
                      </div>
                      <div class="display-first">Boxcatplanet</div>
                      <div class="display-second">
                        The 1st and basic manuscript
                      </div>
                    </div>
                    <div
                      data-w-id="508a4b79-b2be-3763-5ebe-d3963be41982"
                      class="content-right about"
                    >
                      <div class="right-top">
                        <div>BoxCatPlanet</div>
                        <h2 class="content-title parts">
                          ABOUT
                          <br />
                          THIS PROJECT
                        </h2>
                      </div>
                      <p class="content-sub">
                        BoxcatPlanet is a collection of original avatars created
                        by artist Guan Chun. It is our beautiful fantasy of the
                        Metaverse! It gathers our love, recollection and long-
                        cherished wishes created together! Although our pet cats
                        are in different space, we will always be together! Love
                        will never tear us apart!
                        <br />
                        <br />
                        The NFT collection can make you one of the project
                        sponsors. You will witness the process of a cat image
                        creation becoming an IP, and you will decide the future
                        direction of the project as the founder.
                      </p>
                    </div>
                  </animated.div>
                );
              }}
            </Spring>
          )}
        </VisibilitySensor>
      </div>
    </section>
  );
};
