import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Spring, animated } from "react-spring";

import partsImg from "../../pages/home/images/Guan-Chun-p-2000.jpeg";
import parts500Img from "../../pages/home/images/Guan-Chun-p-500.jpeg";
import firstBgImg from "../../pages/home/images/first-bg.png";
import firstBg500Img from "../../pages/home/images/first-bg-p-500.png";
import firstBg800Img from "../../pages/home/images/first-bg-p-800.png";
import firstBg1080Img from "../../pages/home/images/first-bg-p-1080.png";

export const Artist = () => {
  return (
    <section
      id="artist"
      data-w-id="40713488-c4e3-8e66-9afa-7ec420c6688c"
      class="section artist"
    >
      <div class="container artist">
        <VisibilitySensor partialVisibility offset={{ bottom: 80 }}>
          {({ isVisible }) => (
            <Spring
              delay={150}
              from={{ transform: "translateY(100px)" }}
              to={{ opacity: isVisible ? 1 : 0, transform: "translateY(0)" }}
            >
              {(styles) => {
                return (
                  <animated.div
                    data-w-id={"40713488-c4e3-8e66-9afa-7ec420c6688e"}
                    class="content-wrapper parts high"
                    style={styles}
                  >
                    <div
                      data-w-id="40713488-c4e3-8e66-9afa-7ec420c6688f"
                      class="content-right parts"
                    >
                      <div class="right-top _2nd">
                        <h2 class="content-title parts">About Artist</h2>
                      </div>
                      <p class="content-sub parts">
                        Guan Chun is the artist and founder of BoxcatPlanet. She
                        is a visual artist and illustrator of the post-80s
                        generation. Guan focuses on fashion illustration and
                        visual art, and became the favorite artist of lots of
                        famous brands.
                        <br />
                      </p>
                      <div class="square-dot"></div>
                    </div>
                    <div
                      data-w-id="40713488-c4e3-8e66-9afa-7ec420c6689d"
                      class="content-left parts"
                    >
                      <img
                        src={partsImg}
                        loading="lazy"
                        sizes="(max-width: 479px) 90vw, (max-width: 767px) 91vw, (max-width: 991px) 53vw, 39vw"
                        srcSet={`${parts500Img} 500w, ${partsImg} 521w`}
                        alt="Image boxplanet"
                        class="parts-img artist"
                      />
                      <div class="abs-bar">
                        <div class="content-small parts">
                          <a
                            target="_blank"
                            href="https://www.guanchunart.com/"
                            class="artist-link"
                          >
                            www.guanchunart.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </animated.div>
                );
              }}
            </Spring>
          )}
        </VisibilitySensor>
        <img
          src={firstBgImg}
          loading="lazy"
          sizes="100vw"
          srcSet={`${firstBg500Img} 500w, ${firstBg800Img} 800w, ${firstBg1080Img} 1080w, ${firstBgImg} 1366w`}
          alt="Background Image layer"
          class="bg-img"
        />
      </div>
    </section>
  );
};
