import React from "react";

import arrImg from "./images/arr.svg";
import "./index.css";

export const QA = () => {
  const onClickHandler = (idx) => {
    const faqItemContent = document.querySelectorAll("div.faq__item-content");
    const arrImg = document.querySelectorAll("img.faq__arr");
    if (faqItemContent[idx].style.height === "0px") {
      faqItemContent[idx].style.height = "auto";
      arrImg[idx].style.transform =
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg)";
    } else {
      faqItemContent[idx].style.height = "0px";
      arrImg[idx].style.transform =
        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)";
    }
  };

  return (
    <div id={"qa"} class="section section__6 wf-section">
      <div class="container">
        <div data-w-id="95d8c2bd-eae0-533e-6879-e997148aead1" class="faq">
          <div class="qa_head">
            <div class="sm_text sm_text-1">BoxcatPlanet</div>
            <div class="heading__block">
              <h2>FAQ</h2>
              <div class="squre squre-5"></div>
            </div>
          </div>
          <div class="faq__list">
            <div
              data-w-id="4ee5b024-124a-8a54-a8ac-238f6a4fbead"
              class="faq__item"
              onClick={() => onClickHandler(0)}
            >
              <div class="faq__item-head">
                <h4 class="faq__name">What is an NFT?</h4>
                <img
                  src={arrImg}
                  loading={"lazy"}
                  alt={""}
                  class={"faq__arr"}
                />
              </div>
              <div style={{ height: 0 }} class="faq__item-content">
                <div class="faq__item-content-wrap">
                  <p>
                    NFT stands for non-fungible token. NFTs are tokens that we
                    can use to represent ownership of unique items. They let us
                    tokenise things like art, collectibles, even real estate.
                    They can only have one official owner at a time and they're
                    secured by the Ethereum blockchain – no one can modify the
                    record of ownership or copy/ paste a new NFT into existence.
                  </p>
                </div>
              </div>
              <div class="squre faq__square"></div>
            </div>
            <div
              data-w-id="7bdd0437-06dc-898b-cff6-d100d6223413"
              class="faq__item"
              onClick={() => onClickHandler(1)}
            >
              <div class="faq__item-head">
                <h4 class="faq__name">
                  Where can I purchase a BoxcatPlanet NFT?
                </h4>
                <img src={arrImg} loading="lazy" alt="" class="faq__arr" />
              </div>
              <div style={{ height: 0 }} class="faq__item-content">
                <div class="faq__item-content-wrap">
                  <p>
                    BoxcatPlanet NFT will be living in the Ethereum Blockchain.
                    You will need a MetaMask to be able to mint them through our
                    website during our presale and public sale, or you can wait
                    and buy them in the secondary market.
                  </p>
                </div>
              </div>
              <div class="squre faq__square"></div>
            </div>
            <div
              data-w-id="2cd75161-2d6f-8d42-1251-9c939853dccf"
              class="faq__item"
              onClick={() => onClickHandler(2)}
            >
              <div class="faq__item-head">
                <h4 class="faq__name">Is there a mint date and price fixed?</h4>
                <img src={arrImg} loading="lazy" alt="" class="faq__arr" />
              </div>
              <div style={{ height: 0 }} class="faq__item-content">
                <div class="faq__item-content-wrap">
                  <p>
                    The mint date for MintList is September 7th 2022, and for WhiteList and public sale is September 8th 2022. The price for MintList is 0.025, for WhiteList is 0.03, and for public sale is 0.05.
                  </p>
                </div>
              </div>
              <div class="squre faq__square"></div>
            </div>
            <div
              data-w-id="2cd75161-2d6f-8d42-1251-9c939853dccf"
              class="faq__item"
              onClick={() => onClickHandler(3)}
            >
              <div class="faq__item-head">
                <h4 class="faq__name">How can I get access to the WL?</h4>
                <img src={arrImg} loading="lazy" alt="" class="faq__arr" />
              </div>
              <div style={{ height: 0 }} class="faq__item-content">
                <div class="faq__item-content-wrap">
                  <p>
                    You could join our event and contest on Discord, and also
                    participate in campaigns on our Twitter.
                  </p>
                </div>
              </div>
              <div class="squre faq__square"></div>
            </div>
            <div
              data-w-id="2cd75161-2d6f-8d42-1251-9c939853dccf"
              class="faq__item"
              onClick={() => onClickHandler(4)}
            >
              <div class="faq__item-head">
                <h4 class="faq__name">How can I be a part of the community?</h4>
                <img src={arrImg} loading="lazy" alt="" class="faq__arr" />
              </div>
              <div style={{ height: 0 }} class="faq__item-content">
                <div class="faq__item-content-wrap">
                  <p>
                    Join our Discord. We have various events to bring our
                    community together.
                  </p>
                </div>
              </div>
              <div class="squre faq__square"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
