import React from "react";
import { Header } from "../../components/header";
import { Banner } from "../../components/banner";
import { MediaPlayer } from "../../components/mediaplayer";
import { About } from "../../components/about";
import { Artist } from "../../components/artist";
import { Team } from "../../components/team";
import { Gallery } from "../../components/gallery";
import { Roadmap } from "../../components/roadmap";
import { QA } from "../../components/QA";
import { Footer } from "../../components/footer";

import styles from "./index.module.css";
import "./index.css";

export const Home = () => {
  return (
    <div className={styles.homeWrapper}>
      <Header />
      <Banner />
      <MediaPlayer />
      <About />
      <Artist />
      <Team />
      <Gallery />
      <Roadmap />
      <QA />
      <Footer />
    </div>
  );
};
