import React from "react";
import { Header } from "../../components/header";
import { FooterBanner } from "../../components/footer/banner";
import { MediaPlayer } from "../../components/mediaplayer";

import { NfcPage } from "./mintpage/mintPage";

import styles from "./index.module.css";
import "./index.css";

export const Nfc = ({ data }) => {
  return (
    <div className={styles.homeWrapper}>
      <Header />
      <NfcPage data={data} />
      <MediaPlayer />
      <div class="footer-mint">
        <FooterBanner />
      </div>
    </div>
  );
};
