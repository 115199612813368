import React from "react";
import { BsTwitter } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import { OPENSEA_URL, ELEMENT_URL } from "../../utils/configuration";

import logoImg from "../../pages/home/images/logo.png";
import logo500Img from "../../pages/home/images/logo-p-500.png";
import openseaImg from "../../pages/home/images/opensea.png";
import elementImg from "../../pages/home/images/white_logo-01.png";

import "./index.css";

export const Header = () => {
  return (
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      class="navbar w-nav"
    >
      <div class="header_container">
        <div class="navbar__wrap">
          <nav role="navigation" class="nav-menu w-nav-menu">
            <div class="nav__list">
              <a href={"/"} aria-disabled="true" class="nav__link">
                <img
                  src={logoImg}
                  loading="lazy"
                  sizes="(max-width: 479px) 65vw, (max-width: 767px) 155.828125px, (max-width: 991px) 108.2890625px, 11vw"
                  srcSet={`${logo500Img} 500w, ${logoImg} 561w`}
                  alt=""
                  class="logo"
                />
              </a>
            </div>
            <div class="nav__list nav_gap">
              <a href="/#about" class="nav__link">
                about
              </a>
              <a href="/#roadmap" class="nav__link">
                Roadmap
              </a>
              <a href="/#qa" class="nav__link">
                FAQ
              </a>
              <a
                aria-disabled="true"
                class="nav__link"
                target="_blank"
                rel="noreferrer"
                href={"https://medium.com/@BoxcatPlanet"}
              >
                blog
              </a>
              <a
                aria-disabled="true"
                class="nav__link"
                href="/mint"
              >
                mint
              </a>
              {/* <a
                href={"/shop"}
                aria-disabled="true"
                class="nav__link"
              >
                shop
              </a> */}
              <a
                href={"/"}
                aria-disabled="true"
                style={{ color: "grey", cursor: "default" }}
                class="nav__link"
              >
                gallery
              </a>

              <a
                href={ELEMENT_URL}
                target="_blank"
                rel="noreferrer"
                class="nav__link"
              >
                <img
                  src={elementImg}
                  style={{ width: "20px" }}
                  loading="lazy"
                  alt="Opeansea"
                />
              </a>
              <a
                href={OPENSEA_URL}
                target="_blank"
                rel="noreferrer"
                class="nav__link"
              >
                <img
                  src={openseaImg}
                  style={{ width: "20px" }}
                  loading="lazy"
                  alt="Opeansea"
                />
              </a>
              <a
                href="https://t.co/vLz3ZHfBDv"
                class="nav__link"
                target="_blank"
                rel="noreferrer"
              >
                <FaDiscord size={20} loading="lazy" alt="" />
              </a>
              <a
                href="https://twitter.com/boxcatplanet"
                class="nav__link"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitter size={20} loading="lazy" alt="" />
              </a>
              <a
                href="https://www.instagram.com/boxcatplanet"
                class="nav__link"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillInstagram size={20} loading="lazy" alt="" />
              </a>
            </div>
          </nav>
          <div class="menu-button w-nav-button">
            <div class="w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
