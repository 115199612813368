// Set smart contract based on chain id
export const CONTRACTADDRESS = {
  1: "0x13BD972B0bfaefC9538a43c1FDA11D71C720cD47",
  5: "0x5596261367139E289c8D354F0Cb0aeD4CAea977F",
};

// Set the price of your NFT
export const PUBLIC_PRICE_WEI = "50000000000000000";

// Set the max amount of your NFT collection
export const MAX_AMOUNT = 3000;

// Set the max batch size per wallet
export const MAX_BATCH_SIZE = 10;

// Set the opesnea url
export const OPENSEA_URL = "https://opensea.io/collection/boxcatplanet-official";
export const ELEMENT_URL = "https://element.market/collections/boxcatplanet-official";
export const LOOKSRARE_URL = "https://opensea.io/collection/";
export const X2Y2_URL = "https://opensea.io/collection/";


export const whitelistAddresses = [
  "0x4b6Ef4Fe7549d71e3F9945F027a70E5Cb5cd8Cf4",
];
