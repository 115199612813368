import React from "react";

import videos from "../../pages/home/videos/embed-transcode.mp4";

export const Gallery = () => {
  return (
    <section id="gallery" class="section gallery">
      <div class="container">
        <div
          dangerouslySetInnerHTML={{
            __html: `<video autoplay loop muted playsinline width="100%"
                  height="100%">
                  <source src=${videos} type="video/mp4" />
                  Your browser does not support the video tag.
            </video>`,
          }}
        />
        {/* <video
          src={videos}
          width="100%"
          height="100%"
          loop
          autoPlay={"autoplay"}
          muted
        /> */}
      </div>
      <div
        data-w-id="2b88078a-0714-7114-653f-f8f9338492ad"
        class="abs-bar video"
      >
        <div class="vert-wrap">
          <div class="content-small video">Earth  </div>
          <div class="separator landscape"></div>
          {/* <div class="content-small video"> <br /></div> */}
        </div>
        <div class="vert-wrap">
          <div class="content-small video">Travel  </div>
          <div class="separator landscape"></div>
          {/* <div class="content-small video"> <br /></div> */}
        </div>
        <div class="vert-wrap">
          <div class="content-small video">Meta  </div>
          <div class="separator landscape"></div>
          {/* <div class="content-small video"> <br /></div> */}
        </div>
      </div>
    </section>
  );
};
