import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { Mint } from "./pages/mint";
import React from "react";
import { Shop } from "./pages/shop";
import { Nfc } from "./pages/nfc";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      provider: {},
      web3: {},
      account: {},
      count: 1,
      mintType: "",
      mintTransaction: "",
      pendingRequest: false,
      connected: false,
      txn: "",
      finish: false,
      mintedNum: 0,
      mintableNum: 2,
      minMintableNum: 1,
      address: "",
      containedModalShow: "",
      modalDialogTitle: "",
      modalDialogBodyText: "",
      modalDialogBodyHref: "",
      mintErrorMsg: "",
      mintStatus: 0,
      checkingAllowlistStatus: null,
      signature: "",  // allowlist signature
      tier: 0,   // user tier 
      price: 0,  // price per NFT in WEI
      recursiveCheck: false,
      currentRound: 0
    };
    this.web3Modal = {};
  }
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home data={this} />} />
          <Route path="/mint" element={<Mint data={this} />} />
          {/* <Route path="/shop" element={<Shop data={this} />} /> */}
          <Route path="/nfc" element={<Nfc data={this} />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
