import React, { useState } from "react";
import { BsPlayFill, BsFillPauseFill, BsFillStopFill } from "react-icons/bs";
import { MdSkipNext } from "react-icons/md";
import audioSrc0 from "./audio/AD ASTRA.mp3";
import audioSrc1 from "./audio/Meow.mp3";
import audioSrc2 from "./audio/the Day My Cat Went Away.mp3";
import audioSrc3 from "./audio/We Are on a Journey.mp3";
import audioSrc4 from "./audio/On the Way to Boxcat Planet.mp3";
import musicCatImg from "../../pages/home/images/music-cat.png";
import soundImg from "./audio/sound.gif";

import "./index.css";

export const MediaPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [songIdx, setSongIdx] = useState(0);
  const songList = [
    {
      author: "Liiii",
      title: "AD ASTRA",
      src: audioSrc0,
    },
    {
      author: "Liiii",
      title: "Meow",
      src: audioSrc1,
    },
    {
      author: "Chen Shi Mu",
      title: "The day my cat went away",
      src: audioSrc2,
    },
    {
      author: "Chen Shi Mu",
      title: "We Are on a Journey",
      src: audioSrc3,
    },
    {
      author: "Chen Shi Mu",
      title: "On the Way to Boxcat Planet",
      src: audioSrc4,
    },
  ];
  return (
    <div>
      <div class="media-player">
        <img
          src={musicCatImg}
          width="52"
          height="80"
          player="album-cover"
          alt=""
          class="cover"
        />
        <div class="control">
          <div player="play-pause" class="play-wrapper">
            {isPlaying ? (
              <BsFillPauseFill
                class="control-icon"
                size={30}
                loading="lazy"
                alt=""
                onClick={() => {
                  const audioElement = document.getElementById("audio");
                  audioElement.pause();
                  setIsPlaying(false);
                }}
              />
            ) : (
              <BsPlayFill
                class="control-icon"
                size={30}
                loading="lazy"
                alt=""
                onClick={() => {
                  const audioElement = document.getElementById("audio");
                  audioElement
                    .play()
                    .then(() => {
                      console.log("music is playing");
                    })
                    .catch((e) => {
                      console.log(e.message);
                    });
                  setIsPlaying(true);
                }}
              />
            )}
            <BsFillStopFill
              class="control-icon"
              size={30}
              loading="lazy"
              alt=""
              onClick={() => {
                const audioElement = document.getElementById("audio");
                audioElement.pause();
                audioElement.currentTime = 0;
                setIsPlaying(false);
              }}
            />
            <MdSkipNext
              class="control-icon"
              size={30}
              loading="lazy"
              alt=""
              onClick={() => {
                const audioElement = document.getElementById("audio");
                audioElement.currentTime = 0;
                var nextSongIdx =
                  songIdx + 1 >= songList.length ? 0 : songIdx + 1;
                audioElement.src = songList[nextSongIdx].src;
                setSongIdx(nextSongIdx);

                if (isPlaying) {
                  audioElement.pause();
                  setTimeout(function () {
                    audioElement.play();
                  }, 150);
                  setIsPlaying(true);
                }
              }}
            />
          </div>
        </div>
        <div class="textWrapper">
          <div player="song-title" class="title">
            {songList[songIdx].title}
          </div>
          <div player="song-title" class="subtitle">
            {songList[songIdx].author}
          </div>
        </div>
        <img
          class="control-icon"
          src={soundImg}
          style={{
            width: "30px",
            height: "30px",
            "margin-left": "-1em",
            "margin-right": "2em",
            display: isPlaying ? "inline-block" : "none",
          }}
          alt=""
        />
      </div>

      <audio id="audio" src={songList[songIdx].src} loop />
    </div>
  );
};
