import React from "react";
import footerBgImg from "../../pages/home/images/footer__bg.png";
import footerBg500Img from "../../pages/home/images/footer__bg-p-500.png";
import footerBg800Img from "../../pages/home/images/footer__bg-p-800.png";
import footerBg1080Img from "../../pages/home/images/footer__bg-p-1080.png";
import footerBg1600Img from "../../pages/home/images/footer__bg-p-1600.png";
import footerBg2000Img from "../../pages/home/images/footer__bg-p-2000.png";

export const FooterImage = () => {
    return (
        <img
            src={footerBgImg}
            loading="lazy"
            sizes="100vw"
            srcSet={`${footerBg500Img} 500w, ${footerBg800Img} 800w, ${footerBg1080Img} 1080w, ${footerBg1600Img} 1600w, ${footerBg2000Img} 2000w, ${footerBgImg} 2732w"`}
            alt=""
            class="bg__footer"
        />
    )
}